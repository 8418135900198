import { BackedBySection } from "./components/BackedBySection";
import { DeploySection } from "./components/DeploySection";
import { FindAndFixSection } from "./components/FindAndFixSection";
import { GetStartedSection } from "./components/GetStartedSection";
import { HeroSection } from "./components/HeroSection";
import OpenSourceSection from "./components/OpenSourceSection";
import { ReliabilitySection } from "./components/ReliabilitySection";
import { TestimonialsSection } from "./components/TestimonialsSection";
import { TrustedBySection } from "./components/TrustedBySection";
import { VideoFeaturesSection } from "./components/VideoFeaturesSection";
import { TechStackSection } from "./components/TechStackSection";
import type { V2_MetaFunction } from "@remix-run/node";
import ogImagePath from "~/images/og-images/og-image.jpg";
import { description, image, title, twitter, url } from "~/utils/meta";

export const meta: V2_MetaFunction = ({ data, matches }) => {
  return [
    ...title("Trigger.dev | Open source background jobs with no timeouts."),
    ...description(
      "Build and deploy reliable background jobs with no timeouts and no infrastructure to manage."
    ),
    ...image({ matches, path: ogImagePath }),
    ...url(matches, ""),
    ...twitter(),
    {
      tagName: "link",
      rel: "canonical",
      href: "https://trigger.dev",
    },
  ];
};

export default function PublicSiteIndex() {
  return (
    <>
      <HeroSection />
      <TrustedBySection />
      <VideoFeaturesSection />
      <DeploySection />
      <FindAndFixSection />
      <ReliabilitySection />
      <TechStackSection />
      <OpenSourceSection />
      <TestimonialsSection />
      <BackedBySection />
      <GetStartedSection />
    </>
  );
}
