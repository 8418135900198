import { CodeViewerSection } from "~/components/CodeViewerSection";

const tabs = [
  {
    id: "task-retrying",
    title: "Task retrying",
    body: "Configure automatic retrying for tasks.",
    code: `
import { task } from "@trigger.dev/sdk/v3";

export const simpleTask = task({
  id: "simple-task",
  retry: {
    maxAttempts: 3,
    minTimeoutInMs: 1000,
    maxTimeoutInMs: 5000,
    factor: 2,
    randomize: true,
  },
  run: async (payload, { ctx }) => {
    logger.log(\`Attempt \${ctx.attempt.number}\`);

    try {
      throw new Error("This is an error.");
    } catch (error) {
      // The error was caught, so no retry
    }

    throw new Error("This will cause a retry.");
  },
});
`,
  },
  {
    id: "handle-error",
    title: "handleError()",
    body: "Conditional retrying based on the error and run.",
    code: `
import { task } from "@trigger.dev/sdk/v3";

export const openaiTask = task({
  id: "openai-task",
  retry: {
    maxAttempts: 1,
  },
  run: async (payload: { prompt: string }) => {
    const chatCompletion = await openai.chat.completions.create({
      messages: [{ role: "user", content: payload.prompt }],
      model: "gpt-3.5-turbo",
    });

    return chatCompletion.choices[0].message.content;
  },
  handleError: async (payload, err, { ctx, retryAt }) => {
    if (err instanceof OpenAI.APIError) {
      logger.log("OpenAI API error", { err });

      return {
        error: new Error("Custom OpenAI API error"),
        retryDelayInMs: 10_000,
      };
    }
  },
});
`,
  },
  {
    id: "retry-onthrow",
    title: "retry.onThrow()",
    body: "Fine-grained retrying inside tasks.",
    code: `
import { task, logger, retry } from "@trigger.dev/sdk/v3";

export const retryOnThrow = task({
  id: "retry-on-throw",
  run: async (payload: any) => {
    // Will retry up to 3 times. If it fails 3 times it will throw.
    const result = await retry.onThrow(
      async ({ attempt }) => {
        // Throw on purpose the first 2 times, obviously this is a contrived example
        if (attempt < 3) throw new Error("failed");
        //...
        return {
          foo: "bar",
        };
      },
      { maxAttempts: 3, randomize: false }
    );

    // This will log out after 3 attempts of retry.onThrow
    logger.info("Result", { result });
  },
});
`,
  },
  {
    id: "fetch-retrying",
    title: "retry.fetch()",
    body: "Automatically retry requests based on the response.",
    code: `
import { task, logger, retry } from "@trigger.dev/sdk/v3";

export const taskWithFetchRetries = task({
  id: "task-with-fetch-retries",
  run: async ({ payload, ctx }) => {
    // If the Response is a 429 (too many requests), it will retry using the data from the response. 
    // Good APIs send these headers.
    const headersResponse = await retry.fetch("http://my.host/test-headers", {
      retry: {
        byStatus: {
          "429": {
            strategy: "headers",
            limitHeader: "x-ratelimit-limit",
            remainingHeader: "x-ratelimit-remaining",
            resetHeader: "x-ratelimit-reset",
            resetFormat: "unix_timestamp_in_ms",
          },
        },
      },
    });
    const json = await headersResponse.json();
    logger.info("Fetched headers response", { json });
  }
});
`,
  },
  {
    id: "trigger-config",
    title: "trigger.config",
    body: "Configure default retrying in your config file.",
    code: `
export const config: TriggerConfig = {
  project: "proj_yubjwjsfkxnylobaqvqz",
  retries: {
    enabledInDev: true,
    default: {
      maxAttempts: 4,
      minTimeoutInMs: 1000,
      maxTimeoutInMs: 10000,
      factor: 2,
      randomize: true,
    },
  },
};
    `,
  },
];

const containerVariants = {
  visible: { transition: { staggerChildren: 0.1 } },
};

const tabVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { type: "ease-in-out" },
  },
};

export function ReliabilitySection() {
  return (
    <CodeViewerSection
      sectionId="reliability"
      tabs={tabs}
      sectionTitle="Reliable by default"
      docsLink="https://trigger.dev/docs/v3/errors-retrying"
    />
  );
}
