import {
  ArrowsPointingOutIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { RotateCcwIcon } from "~/components/icons/custom-icons/RotateCcwIcon";
import { Section } from "~/components/primitives/Section";
import { Paragraph } from "~/components/primitives/text/Paragraph";
import { SectionHeader } from "~/components/primitives/text/SectionHeader";

interface ExtendedDocument extends Document {
  webkitFullscreenElement?: Element;
  webkitExitFullscreen?: () => Promise<void>;
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  mozCancelFullScreen?: () => Promise<void>;
  msExitFullscreen?: () => Promise<void>;
}

interface HTMLDivElementWithWebkit extends HTMLDivElement {
  webkitRequestFullscreen?: () => Promise<void>;
  mozRequestFullScreen?: () => Promise<void>;
}

interface HTMLVideoElementWithWebkit extends HTMLVideoElement {
  webkitEnterFullScreen?: () => void;
}

export function VideoFeaturesSection() {
  const playerRef = useRef<HTMLVideoElementWithWebkit>(null);
  const containerRef = useRef<HTMLDivElementWithWebkit>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [onscreen, setOnscreen] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setOnscreen(true);
          playerRef.current?.play();
        } else {
          setOnscreen(false);
          playerRef.current?.pause();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, []);

  const togglePlayPause = () => {
    if (!playerRef.current) return;

    if (playerRef.current.paused) {
      playerRef.current.play();
    } else {
      playerRef.current.pause();
    }
  };

  const toggleFullScreen = () => {
    const video = playerRef.current;
    const doc = document as ExtendedDocument;

    if (!containerRef.current || !video) return;

    const isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (doc.webkitFullscreenElement && doc.webkitFullscreenElement !== null) ||
      (doc.mozFullScreenElement && doc.mozFullScreenElement !== null) ||
      (doc.msFullscreenElement && doc.msFullscreenElement !== null);

    if (!isInFullScreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen();
      } else if (video.webkitEnterFullScreen) {
        video.webkitEnterFullScreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }
    }
  };

  const restartVideo = async () => {
    if (!playerRef.current) return;
    playerRef.current.currentTime = 0;
  };

  return (
    <Section id="how-it-works">
      <SectionHeader
        removeBorderT
        title="How it works"
        buttons={
          <div className="flex w-full items-center gap-6 px-2 py-1 sm:gap-4 sm:px-4">
            <button
              onClick={togglePlayPause}
              aria-label="Play and pause video"
              className="group flex items-center gap-1.5 transition ease-in-out hover:cursor-pointer"
            >
              {isPlaying ? (
                <PauseIcon className="size-4 text-dimmed transition group-hover:text-bright" />
              ) : (
                <PlayIcon className="relative left-0.5 size-4 text-dimmed transition group-hover:text-bright" />
              )}
              <Paragraph
                variant="small"
                className="transition group-hover:text-bright"
              >
                {isPlaying ? "Pause" : "Play"}
              </Paragraph>
            </button>
            <button
              onClick={restartVideo}
              aria-label="Restart video"
              className="group flex items-center gap-1.5 transition ease-in-out hover:cursor-pointer"
            >
              <RotateCcwIcon className="size-4 text-dimmed transition sm:group-hover:text-bright" />
              <Paragraph
                variant="small"
                className="transition group-hover:text-bright"
              >
                Replay
              </Paragraph>
            </button>
            <button
              onClick={toggleFullScreen}
              aria-label="Toggle fullscreen"
              className="group flex items-center gap-1.5 transition ease-in-out hover:cursor-pointer"
            >
              <ArrowsPointingOutIcon className="size-4 text-dimmed transition group-hover:text-bright" />
              <Paragraph
                variant="small"
                className="whitespace-nowrap transition group-hover:text-bright"
              >
                Full screen
              </Paragraph>
            </button>
          </div>
        }
      />
      <div
        ref={containerRef}
        className="relative mx-3 grid aspect-[16/9] max-w-[76.5rem] grid-cols-1 overflow-hidden border border-grid-dimmed sm:col-span-12 xl:mx-auto"
      >
        <div className="absolute left-0 top-0 z-10 h-px w-full">
          <motion.div
            className="h-full bg-gradient-to-r from-charcoal-600 via-charcoal-600 to-charcoal-400"
            style={{ width: `${progress}%` }}
            animate={{ width: `${progress}%` }}
            transition={{ ease: "linear" }}
          />
        </div>
        <video
          src="https://content.trigger.dev/howitworks-compressed-1.mp4"
          ref={playerRef}
          controls={false}
          playsInline
          preload="auto"
          loop
          muted
          autoPlay={onscreen}
          className="absolute inset-0 h-full w-full"
          width="100%"
          height="100%"
          onTimeUpdate={() => {
            if (!playerRef.current) return;
            setProgress(
              (playerRef.current.currentTime / playerRef.current.duration) * 100
            );
          }}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </div>
    </Section>
  );
}
