import { GridContent } from "~/components/primitives/GridRow";
import { Header3 } from "~/components/primitives/text/Header";
import { Paragraph } from "~/components/primitives/text/Paragraph";
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import { PatternHoverBackground } from "~/components/primitives/PatternHoverBackground";
import { useState, useEffect } from "react";
import { cn } from "~/utils/cn";

export function NoServersToManage() {
  return (
    <GridContent
      removePaddingX
      removePaddingY
      defaultColSpans
      className="group grid grid-cols-1 place-items-center justify-center overflow-hidden lg:h-[260px] lg:grid-cols-2 xl:h-[20rem]"
    >
      <div className="relative order-last col-span-1 flex w-full lg:order-first">
        <PatternHoverBackground
          mouseTracking={false}
          exitOpacity={1}
          spotlightStartPositionX={0}
          spotlightStartPositionY={20}
          backgroundPattern="bg-pattern-crosses-apple"
        >
          <div className="absolute top-0 h-24 w-full bg-gradient-to-b from-background via-background/75 to-background/0 lg:hidden" />
          <AnimatedGraphic animation="/animations/no-servers-to-manage.riv" />
        </PatternHoverBackground>
      </div>
      <div className="z-10 col-span-1 flex flex-col justify-center gap-2 p-6 sm:p-8 lg:max-w-full lg:border-b-0 lg:p-12">
        <div className="flex max-w-full flex-col gap-2 lg:max-w-sm">
          <Header3 variant="small" className="text-apple-500">
            No servers to manage
          </Header3>
          <Paragraph className="font-title text-2xl font-medium text-bright sm:text-2xl lg:text-3xl">
            We deploy your tasks and handle scaling for you.
          </Paragraph>
        </div>
      </div>
    </GridContent>
  );
}

function AnimatedGraphic({ animation }: { animation: string }) {
  const { RiveComponent } = useRive({
    src: animation,
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.BottomCenter,
    }),
    autoplay: true,
  });

  const [isWebkit, setIsWebkit] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("safari") && !userAgent.includes("chrome")) {
      setIsWebkit(true);
    }
  }, []);
  return (
    <div className="relative mx-auto flex max-w-sm justify-center sm:max-w-xl lg:mx-0 lg:justify-start">
      <div
        aria-label="No servers to manage"
        className="px-3 pt-3 lg:px-0 lg:pl-8"
        style={{
          perspective: "2000px",
        }}
      >
        <RiveComponent
          className={cn(
            "h-[178px] w-[300px] transform transition duration-1000 sm:h-[324px] sm:w-[550px] lg:h-[260px] lg:w-[440px] xl:h-[324px] xl:w-[550px]",
            isWebkit
              ? null
              : "md:rotate-y-[20deg] md:group-hover:translate-x-4 md:group-hover:scale-105 md:group-hover:rotate-y-[0deg]"
          )}
        />
      </div>
    </div>
  );
}
