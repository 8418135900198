import { GridContent, GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import { SectionHeader } from "../../../components/primitives/text/SectionHeader";

import AtlassianLogo from "./images/logos/atlassian-logo.png";
import AWSLogo from "./images/logos/aws-logo-padding.png";
import DropboxLogo from "./images/logos/dropbox-logo.png";
import InstabugLogo from "./images/logos/instabug-logo.png";
import PagerDutyLogo from "./images/logos/pagerduty-logo.png";
import RaycastLogo from "./images/logos/raycast-logo.png";
import SupabaseLogo from "./images/logos/supabase-logo.png";
import YCLogo from "./images/logos/yc-logo.png";

type Backer = {
  icon: string;
  title: string;
  href: string;
};

const backers: Backer[] = [
  {
    icon: YCLogo,
    title: "Y Combinator",
    href: "https://www.ycombinator.com/",
  },
  {
    icon: SupabaseLogo,
    title: "Supabase",
    href: "https://supabase.io/",
  },
  {
    icon: PagerDutyLogo,
    title: "PagerDuty",
    href: "https://www.pagerduty.com/",
  },

  {
    icon: DropboxLogo,
    title: "Dropbox",
    href: "https://www.dropbox.com/",
  },
  {
    icon: RaycastLogo,
    title: "Raycast",
    href: "https://raycast.com/",
  },
  {
    icon: InstabugLogo,
    title: "Instabug",
    href: "https://instabug.com/",
  },

  {
    icon: AtlassianLogo,
    title: "Atlassian",
    href: "https://www.atlassian.com/",
  },
  {
    icon: AWSLogo,
    title: "Amazon Web Services",
    href: "https://aws.amazon.com/",
  },
];

export function BackedBySection() {
  return (
    <Section id="backed-by">
      <SectionHeader title="We're backed by the world's best investors, founders and operators" />
      <GridRow
        removeBordersX
        autoGridLines={false}
        className="border-r border-grid-dimmed bg-visible-grid-2 sm:bg-visible-grid-4"
      >
        <GridContent
          className="col-span-1 gap-0 p-0 sm:col-span-12"
          removePaddingY
          removePaddingX
        >
          <div className="grid grid-cols-2 items-center justify-center sm:grid-cols-4">
            {backers.map((backer) => (
              <div
                className="flex h-24 w-full items-center justify-center border-b border-grid-dimmed sm:h-32"
                key={backer.title}
              >
                <img
                  src={backer.icon}
                  alt={backer.title}
                  className="w-24 opacity-80 sm:w-28 xs:w-32"
                />
              </div>
            ))}
          </div>
        </GridContent>
      </GridRow>
    </Section>
  );
}
