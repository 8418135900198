import { motion } from "framer-motion";
import HorizontalSlider from "~/components/HorizontalSlider";
import { GridContent, GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import { Header3 } from "~/components/primitives/text/Header";
import { cn } from "~/utils/cn";

type TechStackLogo = {
  id: string;
  src: string;
  alt: string;
  customClass?: string;
};

const techStackLogos: TechStackLogo[] = [
  {
    id: "vercel",
    src: "/tech-stack/vercel.png",
    alt: "Vercel logo",
    customClass: "pt-1",
  },
  {
    id: "aws",
    src: "/tech-stack/aws.png",
    alt: "AWS logo",
    customClass: "h-9 pb-1.5",
  },
  {
    id: "remix",
    src: "/tech-stack/remix.png",
    alt: "Remix logo",
    customClass: "pt-1",
  },
  {
    id: "nuxt",
    src: "/tech-stack/nuxt.png",
    alt: "Nuxt logo",
  },
  {
    id: "sveltekit",
    src: "/tech-stack/sveltekit.png",
    alt: "SvelteKit logo",
    customClass: "w-32 pt-1",
  },

  {
    id: "fastify",
    src: "/tech-stack/fastify.png",
    alt: "Fastify logo",
    customClass: "pt-1",
  },
  {
    id: "redwoodjs",
    src: "/tech-stack/redwoodjs.png",
    alt: "RedwoodJS logo",
    customClass: "w-28 pt-1",
  },
  {
    id: "cloudflare",
    src: "/tech-stack/cloudflare.png",
    alt: "Cloudflare logo",
    customClass: "pb-4 w-28",
  },
  {
    id: "expressjs",
    src: "/tech-stack/expressjs.png",
    alt: "ExpressJS logo",
    customClass: "w-28 pb-1",
  },
  {
    id: "astro",
    src: "/tech-stack/astro.png",
    alt: "Astro logo",
  },
  {
    id: "google-cloud",
    src: "/tech-stack/google-cloud.png",
    alt: "Google Cloud logo",
    customClass: "w-36",
  },
  {
    id: "azure",
    src: "/tech-stack/azure.png",
    alt: "Azure logo",
    customClass: "w-28 pb-1.5",
  },
  {
    id: "netlify",
    src: "/tech-stack/netlify.png",
    alt: "Netlify logo",
    customClass: "w-28 pt-1.5",
  },
  {
    id: "nextjs",
    src: "/tech-stack/nextjs.png",
    alt: "NextJS logo",
    customClass: "pt-1",
  },
];

export function TechStackSection() {
  return (
    <Section id="works-with-everything">
      <GridRow autoGridLines={false}>
        <div className="col-span-12 h-32 w-full border-b border-l border-grid-dimmed bg-pattern-crosses" />
        <GridContent
          defaultColSpans
          className="grid grid-cols-2 bg-charcoal-900 md:grid-cols-8 lg:divide-y-0"
          removePaddingX
          removePaddingY
        >
          <div className="sm:24 col-span-2 flex h-20 w-full content-center items-center justify-center overflow-hidden p-3 md:h-32">
            <Header3
              variant={"small"}
              className="text-center sm:pl-6 md:text-left"
            >
              Works with your existing tech stack…
            </Header3>
          </div>
          <div className="relative col-span-2 md:col-span-6">
            <div className="absolute left-0 z-10 hidden h-full w-32 bg-gradient-to-r from-background via-background/90 to-background/0 md:block "></div>
            <HorizontalSlider
              loop={true}
              renderMode={"precision"}
              drag={true}
              initial={0}
              className="h-full w-full"
            >
              {techStackLogos.map((logo) => (
                <div
                  className="relative flex h-20 w-40 select-none items-center justify-center sm:h-24 md:h-32"
                  key={logo.id}
                >
                  <motion.img
                    src={logo.src}
                    alt={logo.alt}
                    className={cn(
                      "mx-auto w-24 object-contain",
                      logo.customClass
                    )}
                  />
                </div>
              ))}
            </HorizontalSlider>
          </div>
        </GridContent>
      </GridRow>
    </Section>
  );
}
