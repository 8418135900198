export function transformCode(originalCode: string) {
  const hideCodeRegex = /(\n)?\/\/ hide-code[\s\S]*?\/\/ end-hide-code(\n)*/gm;
  const code = originalCode.replace(hideCodeRegex, "");
  const annotatedCode = extractCommentsAndLineNumbers(code);
  return annotatedCode;
}

export type CodeAnnotation = {
  comment: string;
  start?: number;
  end?: number;
};

export type AnnotatedCode = {
  code: string;
  annotations: CodeAnnotation[];
};

function extractCommentsAndLineNumbers(code: string): AnnotatedCode {
  const lines = code.split("\n");
  const annotations: CodeAnnotation[] = [];
  let currentComment: CodeAnnotation | null = null;
  const outputLines: string[] = [];

  lines.forEach((line, index) => {
    if (line.trim().startsWith("//<>")) {
      if (!currentComment) {
        currentComment = {
          comment: line.trim().substring(4).trim(),
          start: outputLines.length,
          end: -1,
        };
      } else {
        currentComment.comment += "\n" + line.trim().substring(4).trim();
      }
    } else if (line.trim() === "//</>" && currentComment) {
      currentComment.end = outputLines.length - 1;
      annotations.push(currentComment);
      currentComment = null;
    } else {
      outputLines.push(line);
    }
  });

  return {
    annotations: annotations.map((annotation) => {
      if (
        annotation.end === undefined ||
        annotation.start === undefined ||
        annotation.end <= annotation.start
      ) {
        return {
          comment: annotation.comment,
        };
      }
      return annotation;
    }),
    code: outputLines.join("\n"),
  };
}

export function nextAnnotationWithLines(
  annotations: CodeAnnotation[],
  activeAnnotationIndex: number | undefined
): number {
  if (activeAnnotationIndex === undefined) {
    activeAnnotationIndex = -1;
  }
  const index = annotations.findIndex((annotation, index) => {
    if (
      index > activeAnnotationIndex &&
      annotation.start !== undefined &&
      annotation.end !== undefined
    ) {
      return true;
    }
    return false;
  });

  if (index === -1 && activeAnnotationIndex !== -1) {
    return nextAnnotationWithLines(annotations, -1);
  }

  return index;
}
