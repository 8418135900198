import { useRef, useState, useEffect } from "react";

export function useCarouselScroll(itemWidth = 336) {
  const [isFullyScrolledLeft, setIsFullyScrolledLeft] = useState(false);
  const [isFullyScrolledRight, setIsFullyScrolledRight] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        top: 0,
        left: -itemWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        top: 0,
        left: itemWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      setIsFullyScrolledLeft(carousel.scrollLeft === 0);
      setIsFullyScrolledRight(
        carousel.scrollLeft + carousel.clientWidth === carousel.scrollWidth
      );
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return {
    carouselRef,
    scrollLeft,
    scrollRight,
    isFullyScrolledLeft,
    isFullyScrolledRight,
    handleScroll,
  };
}
