import { GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import { SectionHeader } from "../../../components/primitives/text/SectionHeader";
import { NoServersToManage } from "./DeployComponents/NoServersToManage";
import { NoTimeouts } from "./DeployComponents/NoTimeouts";
import { OnlyPayForWhatYouUse } from "./DeployComponents/OnlyPayForWhatYouUse";

export function DeploySection() {
  return (
    <Section id="deploy-and-scale">
      <SectionHeader title="Deploy and scale to any size" removeBorderT />
      <GridRow>
        <NoTimeouts />
      </GridRow>
      <GridRow>
        <OnlyPayForWhatYouUse />
      </GridRow>
      <GridRow>
        <NoServersToManage />
      </GridRow>
    </Section>
  );
}
