import { ExpandableContainer } from "~/components/ExpandableContainer";
import HorizontalSlider from "~/components/HorizontalSlider";
import { DiscordIconWhite } from "~/components/icons/DiscordIcon";
import { AnimatingArrow } from "~/components/primitives/AnimatingArrow";
import { LinkButton } from "~/components/primitives/Buttons";
import { GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import { Paragraph } from "~/components/primitives/text/Paragraph";
import { SectionHeader } from "../../../components/primitives/text/SectionHeader";

type Testimonial = {
  bodyDesktopExtra?: string;
  body: string;
  author: {
    name: string;
    handle: string;
    companyImageUrl: string;
    companyUrl: string;
    imageUrl: string;
  };
};

const testimonials: Testimonial[] = [
  {
    body: "Trigger.dev is redefining background jobs for modern developers.",
    author: {
      name: "Paul Copplestone",
      handle: "Supabase",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/bfd6d823-7027-4c8b-0117-ef4085b76200/public",
      companyUrl: "https://supabase.com/",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/e82deee8-b8ec-4092-3980-b268688d1900/public",
    },
  },
  {
    body: "I ****ing love Trigger.dev - really has been the missing piece in the puzzle for me going full serverless.",
    author: {
      name: "Pontus Abrahamsson",
      handle: "Midday",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/9c0e5ff1-b718-4b67-8e7c-4a45f4697f00/public",
      companyUrl: "https://midday.ai/en",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/d60cc0d1-a132-420a-b7fa-bdb67267f800/public",
    },
  },
  {
    bodyDesktopExtra:
      "We have critical business functionalities that need to be reliable and replayable in the event of a failure.",
    body: "Trigger.dev helps us deliver messages over WhatsApp, run thousands of jobs with custom LLM workflows, and execute ETL processes to sync our data across multiple databases without breaking a sweat!",
    author: {
      name: "Patryk Maron",
      handle: "DRPCRD",
      companyImageUrl: "/testimonials/drpcrd/drpcrd.png",
      companyUrl: "https://drpcrd.com/",
      imageUrl: "/testimonials/drpcrd/patryk-maron.png",
    },
  },
  {
    body: "The first time I used Trigger.dev, I had an a-ha moment, I no longer needed to set up everything with ECS or Lambda. What also stands out is the exceptional support, unlike any I've seen in the web community.",
    author: {
      name: "Martin Ruzicka",
      handle: "P.S. Bridal",
      companyImageUrl: "/testimonials/ps-bridal/ps-bridal.png",
      companyUrl: "https://psbridal.co.uk/",
      imageUrl: "/testimonials/ps-bridal/martin-ruzicka.png",
    },
  },
  {
    body: "We’ve been looking for a product like Trigger.dev for a long time - automation that's simple and dev-focused.",
    author: {
      name: "Han Wang",
      handle: "Mintlify",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/1a068830-bc93-451c-b5c2-f76b24e42000/public",
      companyUrl: "https://mintlify.com/",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/4e3d1187-c27b-4bdc-feac-d2e5297e9a00/public",
    },
  },
  {
    body: "We're using Trigger for our billing, background jobs and deployment pipeline without worrying about operations or infrastructure. It just works.",
    author: {
      name: "Andreas Thomas",
      handle: "Unkey",
      companyImageUrl: "/testimonials/unkey/unkey.png",
      companyUrl: "https://www.unkey.com/",
      imageUrl: "/testimonials/unkey/andreas-thomas-chronark.png",
    },
  },
  {
    body: "Trigger.dev is a breath of fresh air in a world of 20 second lambda timeouts. Trigger has world-class DX, support, and handles my long-running jobs with ease.",
    author: {
      name: "Ian Janicki",
      handle: "PropFeeds",
      companyImageUrl: "/testimonials/propfeeds/propfeeds.png",
      companyUrl: "https://propfeeds.com/",
      imageUrl: "/testimonials/propfeeds/ian-janicki.png",
    },
  },

  {
    body: "I’m in love with Trigger.dev – it’s so much better than the old bull.js + heroku + redis setup that I used to use. You’ve knocked it out of the park with this tool!",
    author: {
      name: "Kushal Byatnal",
      handle: "Extend",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/0db95e34-61a0-47ff-879d-7698f3084900/public",
      companyUrl: "https://www.extend.app/",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/6738d30f-ccd5-4342-8764-a2194fc0d900/public",
    },
  },
  {
    body: "Trigger.dev has become my go-to tool for new projects. I no longer need an additional server and can forget about horizontal scaling!",
    author: {
      name: "Nevo David",
      handle: "Novu",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/c89f7bbe-5b66-4b3d-be59-02bcc3a4e800/public",
      companyUrl: "https://midday.ai/en",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/f577a1b5-e9bc-46ae-af2e-517c8ebef700/public",
    },
  },
  {
    body: "For AI powered products, Trigger.dev is my clear go-to tool for building robust serverless pipelines stitching together various LLM calls.",
    author: {
      name: "Evan Sandler",
      handle: "Blee",
      companyImageUrl: "/testimonials/blee/blee-icon.png",
      companyUrl: "https://www.blee.com/",
      imageUrl: "/testimonials/blee/evan-sandler.png",
    },
  },
  {
    body: "Trigger is packaging end-to-end cron, queues and webhooks platform in a slick interface. Integration was quick and we love the support ❤️",
    author: {
      name: "Aseem Gupta",
      handle: "Shaktimaan",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/cf46d1a3-604d-4eef-2e18-384b767a6600/public",
      companyUrl: "https://shaktimaan.ai/",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/521fe3ce-20f6-4f9b-d8de-b1c3b4724100/public",
    },
  },
  {
    bodyDesktopExtra:
      "We needed a sophisticated event engine: chaining LLM queries, orchestrating responses, async tasks, persistent state (without long lived servers), complex concurrency, and variable compute power.",
    body: "Trigger’s managed infra and intuitive SDK allowed us to migrate our entire events engine in a day (with incredible support from the team).",
    author: {
      name: "Michael Parker",
      handle: "Turnout Labs",
      companyImageUrl: "/testimonials/turnout-labs/turnout-labs.png",
      companyUrl: "https://www.turnoutlabs.ai/",
      imageUrl: "/testimonials/turnout-labs/michael-parker.png",
    },
  },
  {
    body: "Trigger.dev helps us process bounties & tips on Algora without having to duct-tape queues & crons. With standardized timeouts, retries & logging we get full resilience & observability!",
    author: {
      name: "Zaf Cesur",
      handle: "Algora",
      companyImageUrl: "/testimonials/algora/algora.png",
      companyUrl: "https://algora.io/",
      imageUrl: "/testimonials/algora/zafer-cesur.png",
    },
  },
  {
    body: "Trigger.dev is a great way to automate email campaigns with Resend.",
    author: {
      name: "Zeno Rocha",
      handle: "Resend",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/a7941fc0-bfe2-4067-9449-9c593cf54c00/public",
      companyUrl: "https://resend.com",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/3aca9c05-4563-4924-61df-867ec8434200/public",
    },
  },

  {
    body: "Using Trigger.dev for our Slack jobs saved us loads of time! It was much easier to set up than a no-code tool.",
    author: {
      name: "Vlad Matsiiako",
      handle: "Infisical",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/c01ec071-f909-4eed-2f0a-6db7aa885c00/public",
      companyUrl: "https://infisical.com",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/9c9e9c2e-5ff1-4227-f0d3-514aa2e49300/public",
    },
  },
  {
    body: "I really enjoyed using Trigger.dev to create jobs through code. I found the API integrations and scheduling features super useful.",
    author: {
      name: "Adam Shiervani",
      handle: "BuildJet",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/06b5fdf7-f7be-424c-a4df-dfa0e3d93100/public",
      companyUrl: "https://buildjet.com",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/36535ea9-56b4-42e3-4e45-ef08d293dd00/public",
    },
  },
  {
    body: "We love Trigger.dev and it’s had a big impact in dev iteration velocity already.",
    author: {
      name: "André Neves",
      handle: "ZBD",
      companyImageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/9bfbbd56-ec03-43ef-663a-15595ae7ab00/public",
      companyUrl: "https://zbd.gg/",
      imageUrl:
        "https://imagedelivery.net/3TbraffuDZ4aEf8KWOmI_w/3e6a3e0f-e4c9-41e9-3881-ffe4cfc50400/public",
    },
  },
];

export function TestimonialsSection() {
  return (
    <Section id="testimonials">
      <SectionHeader
        title="Loved by developers"
        buttons={
          <LinkButton
            variant="minimal/medium"
            to="https://discord.gg/nkqV9xBYWy"
            LeadingIcon={DiscordIconWhite}
            leadingIconClassName="opacity-60 group-hover:opacity-100"
            target="_blank"
            TrailingIcon={() => (
              <AnimatingArrow variant="small" direction="topRight" />
            )}
          >
            Join our community
          </LinkButton>
        }
      />

      <GridRow
        className="border-l border-r sm:border-l-0 sm:border-grid-dimmed"
        removeBordersX
      >
        <ExpandableContainer
          heightCollapsedPx={600}
          hideOnMobile
          className="col-span-1 overflow-hidden sm:col-span-12 sm:bg-visible-grid-2 lg:bg-visible-grid-3"
          textExpanded="Show less"
          textCollapsed="Read more..."
        >
          <div className="mx-auto">
            <HorizontalSlider
              loop={true}
              renderMode={"precision"}
              drag={true}
              initial={0}
              perViewMobile={1.05}
              modeMobile="free"
              spacingMobile={20}
              disabledDesktop={true}
              disabledTablet={true}
              className="flex h-full gap-x-0 divide-grid-dimmed sm:block sm:w-full sm:cursor-default sm:columns-2 sm:grid-flow-col sm:divide-x sm:text-[0] lg:columns-3 "
            >
              {testimonials.map((testimonial) => (
                <div
                  key={testimonial.author.handle}
                  className="group flex break-inside-avoid-column border-x border-b border-grid-dimmed sm:inline-block sm:w-full sm:border-x-0"
                >
                  <div className="flex h-full flex-col place-content-between overflow-hidden text-sm leading-6 ">
                    <figure className="p-7">
                      <blockquote>
                        <Paragraph
                          className="font-title text-base font-medium sm:text-lg"
                          variant="large"
                        >
                          <span className="hidden sm:inline">
                            {testimonial.bodyDesktopExtra
                              ? `${testimonial.bodyDesktopExtra} `
                              : null}
                          </span>
                          {`${testimonial.body}`}
                        </Paragraph>
                      </blockquote>
                      <figcaption className="mt-6 flex h-10 w-full place-content-between items-center gap-x-4 ">
                        <div className="flex gap-3">
                          <img
                            className="bg-charcoal-50 h-10 w-10 rounded-full"
                            src={testimonial.author.imageUrl}
                            alt=""
                          />
                          <div>
                            <Paragraph variant="small" className=" text-bright">
                              {testimonial.author.name}
                            </Paragraph>
                            <Paragraph variant="small">{`${testimonial.author.handle}`}</Paragraph>
                          </div>
                        </div>
                        <div className="flex h-6 w-12 flex-col items-end">
                          <img
                            className="h-full rounded-md opacity-70 transition duration-300 group-hover:opacity-100"
                            src={testimonial.author.companyImageUrl}
                            alt={`${testimonial.author.handle} + ' logo'`}
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              ))}
            </HorizontalSlider>
          </div>
        </ExpandableContainer>
      </GridRow>
    </Section>
  );
}
