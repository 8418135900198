import { HeartIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { AnimatingArrow } from "~/components/primitives/AnimatingArrow";
import { GridContent, GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import { Header3, Header4 } from "~/components/primitives/text/Header";
import { Paragraph } from "~/components/primitives/text/Paragraph";
import { useDiscordMemberCount, useStarCount } from "~/root";
import Heart1 from "./images/heart/1.png";
import Heart2 from "./images/heart/2.png";
import Heart3 from "./images/heart/3.png";
import Heart4 from "./images/heart/4.png";
import Heart5 from "./images/heart/5.png";

const containerVariants = {
  visible: {
    transition: { staggerChildren: 0.1, staggerDirection: 1 },
  },
  animate: {
    transition: { staggerChildren: 0.1, staggerDirection: 1 },
  },
};

const middleHeartVariants = {
  visible: {
    scale: [0.95, 1.12, 0.94, 1.08, 1.04],
    transition: {
      duration: 0.5,
      ease: "easeInOut",
      repeat: Infinity,
      repeatDelay: 3,
    },
  },
};

const heartVariants = {
  visible: {
    x: "-50%",
    y: "-50%",
    scale: [0.7, 1, 1.2, 1.3, 1.35],
    opacity: [0, 0.6, 0.75, 1, 0],

    transition: {
      duration: 3,
      repeatDelay: 0.5,
      repeat: Infinity,
      ease: "linear",
    },
  },
  hidden: {
    x: "-50%",
    y: "-50%",
    scale: 0,
  },
};

export default function OpenSourceSection() {
  const stargazers = useStarCount();
  const discordMemberCount = useDiscordMemberCount();

  return (
    <Section id="open-source">
      <GridRow className="overflow-clip">
        <GridContent justifyCenter className="col-span-12">
          <div className="mx-auto grid max-w-2xl place-items-center gap-2 px-4 py-24 md:gap-4 md:px-2 md:py-40 lg:px-0">
            <div className="group relative flex select-none  items-center justify-center">
              <motion.div
                variants={middleHeartVariants}
                whileInView="visible"
                title="heart"
                className="z-0 drop-shadow-md"
              >
                <HeartIcon className="h-20 w-20 text-rose-500 shadow-black drop-shadow-xl transition duration-300 group-hover:scale-105 md:h-24 md:w-24" />
              </motion.div>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                className="pointer-events-none absolute left-1/2 top-1/2 -z-10 h-screen w-screen -translate-x-1/2 -translate-y-1/2"
              >
                <motion.img
                  src={Heart1}
                  alt="Heart 1"
                  variants={heartVariants}
                  className="pointer-events-none absolute left-1/2 top-1/2 -z-10 -translate-x-1/2 -translate-y-1/2 object-cover"
                />
                <motion.img
                  src={Heart2}
                  alt="Heart 2"
                  variants={heartVariants}
                  className="pointer-events-none absolute left-1/2 top-1/2 -z-20 -translate-x-1/2 -translate-y-1/2 object-cover"
                />
                <motion.img
                  src={Heart3}
                  alt="Heart 3"
                  variants={heartVariants}
                  className="pointer-events-none absolute left-1/2 top-1/2 -z-30 -translate-x-1/2 -translate-y-1/2 object-cover"
                />

                <motion.img
                  src={Heart4}
                  alt="Heart 4"
                  variants={heartVariants}
                  className="pointer-events-none absolute left-1/2 top-1/2 -z-40 -translate-x-1/2 -translate-y-1/2 object-cover"
                />
                <motion.img
                  src={Heart5}
                  alt="Heart 5"
                  variants={heartVariants}
                  className="pointer-events-none absolute left-1/2 top-1/2 -z-50 -translate-x-1/2 -translate-y-1/2 object-cover"
                />
              </motion.div>
            </div>
            <Header3
              variant="extra-large"
              className="text-dimmed transition duration-300"
              textCenter
            >
              <span className="text-bright">We love open source. </span>
              Trigger.dev is Apache 2.0 licensed so you can view the source
              code, contribute and self-host.
            </Header3>
          </div>
        </GridContent>
      </GridRow>
      <GridRow autoGridLines={false}>
        <GridContent
          className="group col-span-12 transition duration-300 hover:animate-moving-lines hover:bg-pattern-diagonal-lines-left sm:col-span-4"
          removePaddingX
          removePaddingY
        >
          <a
            className="relative grid h-full w-full content-center gap-3 border-b border-grid-dimmed py-6 sm:border-b-0 sm:border-r md:py-10"
            href="https://github.com/triggerdotdev/trigger.dev"
            target="_blank"
            rel="noreferrer"
          >
            <AnimatingArrow
              variant="medium"
              theme="primary"
              direction="topRight"
              className="absolute right-3 top-3 h-4 w-4  text-apple-500 opacity-0 transition duration-300  group-hover:opacity-100"
            />
            <Header4
              variant="extra-extra-extra-large"
              textCenter
              className="text-4xl text-featureText tracking-wider sm:text-4xl lg:text-5xl"
            >
              {roundCommunityCounts(stargazers)}
            </Header4>
            <Paragraph textCenter>stars on GitHub</Paragraph>
          </a>
        </GridContent>
        <GridContent
          className="group col-span-12 transition duration-300 hover:animate-moving-lines hover:bg-pattern-diagonal-lines-left sm:col-span-4 "
          removePaddingX
          removePaddingY
        >
          <a
            className="relative grid h-full w-full content-center gap-3 border-b border-grid-dimmed py-6 sm:border-b-0 sm:border-r md:py-10"
            href="https://github.com/triggerdotdev/trigger.dev?tab=Apache-2.0-1-ov-file#readme"
            target="_blank"
            rel="noreferrer"
          >
            <AnimatingArrow
              variant="medium"
              theme="primary"
              direction="topRight"
              className="absolute right-3 top-3 h-4 w-4  text-apple-500 opacity-0 transition duration-300 group-hover:opacity-100"
            />
            <Header4
              variant="extra-extra-extra-large"
              textCenter
              className="text-4xl text-featureText sm:text-4xl md:tracking-wide lg:text-5xl"
            >
              Apache 2.0
            </Header4>
            <Paragraph textCenter>open source license</Paragraph>
          </a>
        </GridContent>
        <GridContent
          className="group col-span-12 transition delay-300 duration-300 hover:animate-moving-lines hover:bg-pattern-diagonal-lines-left sm:col-span-4"
          removePaddingX
          removePaddingY
        >
          <a
            className="relative grid h-full w-full content-center gap-3 py-6 md:py-10"
            href="https://discord.gg/nkqV9xBYWy"
            target="_blank"
            rel="noreferrer"
          >
            <AnimatingArrow
              variant="medium"
              theme="primary"
              direction="topRight"
              className="absolute right-3 top-3 h-4 w-4  text-apple-500 opacity-0 transition duration-300 group-hover:opacity-100"
            />
            <Header4
              variant="extra-extra-extra-large"
              textCenter
              className="text-4xl text-featureText tracking-wider sm:text-4xl  lg:text-5xl"
            >
              {roundCommunityCounts(discordMemberCount)}
            </Header4>
            <Paragraph textCenter>Discord members</Paragraph>
          </a>
        </GridContent>
      </GridRow>
    </Section>
  );
}

function roundCommunityCounts(num: number): string {
  const roundedNum = Math.round(num / 100) * 100;
  return roundedNum.toLocaleString() + "+";
}
