import { Fragment, useEffect, useRef, useState } from "react";
import { cn } from "~/utils/cn";
import type { AnnotatedCode } from "~/utils/transformCode";
import { nextAnnotationWithLines } from "~/utils/transformCode";
import { GridContent, GridRow } from "../primitives/GridRow";
import { Paragraph } from "../primitives/text/Paragraph";
import { CodeBlock } from "./CodeBlock";
import { LinkButton } from "../primitives/Buttons";
import { AnimatingArrow } from "../primitives/AnimatingArrow";

export type AnimatedCodeHighlightingProps = {
  fileName?: string;
  title: string;
  annotatedCode: AnnotatedCode;
  isActive: boolean;
  initialDelay?: number;
  period?: number;
  buttonText?: string;
  buttonLink?: string;
};

export function AnimatedCodeHighlighting({
  title,
  annotatedCode,
  isActive,
  initialDelay,
  period = 2000,
  buttonText,
  buttonLink,
}: AnimatedCodeHighlightingProps) {
  const { code, annotations } = annotatedCode;
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(
    initialDelay ? false : true
  );
  const timer = useRef<NodeJS.Timer>();

  const [activeAnnotationIndex, setActiveAnnotationIndex] = useState<
    number | undefined
  >(undefined);

  const activeAnnotation =
    activeAnnotationIndex !== undefined
      ? annotations[activeAnnotationIndex]
      : undefined;

  useEffect(() => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      if (shouldAnimate) {
        let nextIndex = nextAnnotationWithLines(
          annotations,
          activeAnnotationIndex
        );
        setActiveAnnotationIndex(nextIndex);
        return nextIndex;
      }
    }, period);

    return () => {
      clearInterval(timer.current);
    };
  }, [activeAnnotationIndex, shouldAnimate, period, annotations]);

  useEffect(() => {
    if (!initialDelay) return;
    setTimeout(() => {
      setShouldAnimate(true);
    }, initialDelay);
  }, [initialDelay]);

  return (
    <GridRow
      removeBorderT
      autoResponsiveGrid={false}
      autoGridLines={false}
      className="min-h-[32rem] grid-cols-1 sm:grid-cols-1 md:grid-cols-12"
    >
      <GridContent
        removePaddingX
        removePaddingY
        className="order-2 col-span-1 border-r-0 border-t border-grid-dimmed pl-2 md:order-none md:col-span-6 md:border-r md:border-t-0 lg:col-span-7"
      >
        <div
          className={cn(
            "h-full w-full overflow-hidden transition duration-300",
            isActive ? "opacity-100" : "opacity-40"
          )}
          onMouseEnter={() => {
            setShouldAnimate(false);
            setActiveAnnotationIndex(-1);
          }}
          onMouseLeave={() => {
            setShouldAnimate(true);
          }}
        >
          <CodeBlock
            code={code}
            highlightedRanges={
              activeAnnotation !== undefined &&
              activeAnnotation.start !== undefined &&
              activeAnnotation.end !== undefined
                ? [[activeAnnotation.start, activeAnnotation.end]]
                : undefined
            }
            showCopyButton={false}
            showLineNumbers={false}
            removeBorder
            hideLineNumbersOnSmallDevices
          />
        </div>
      </GridContent>
      <GridContent className="order-1 col-span-1 flex flex-col place-content-between pl-[1.2rem] md:order-none md:col-span-6 md:place-items-end lg:col-span-5">
        {/* <span
          onMouseLeave={() => {
            setShouldAnimate(true);
          }}
          onMouseEnter={() => {
            setActiveAnnotationIndex(-1);
            setShouldAnimate(false);
          }}
        > */}
        {title && (
          <Paragraph className="mb-3 text-balance font-title text-2xl font-[600] text-dimmed sm:text-3xl sm:leading-snug">
            {title}
          </Paragraph>
        )}
        {/* <Paragraph className="text-balance font-title text-2xl font-[600] text-dimmed sm:text-3xl sm:leading-snug">
            {annotations.map((annotation, index) => {
              const isInteractive =
                annotation.start !== undefined && annotation.end !== undefined;
              return (
                <Fragment key={index}>
                  <span
                    onMouseLeave={() => {
                      setShouldAnimate(true);
                    }}
                    onMouseEnter={() => {
                      if (!isInteractive) return;
                      setActiveAnnotationIndex(index);
                      setShouldAnimate(false);
                    }}
                    className={cn(
                      isInteractive ? "cursor-pointer" : "",
                      activeAnnotationIndex === index
                        ? "text-charcoal-300 transition duration-300 hover:text-charcoal-300"
                        : isInteractive
                        ? "text-charcoal-500"
                        : "text-charcoal-200"
                    )}
                  >
                    {annotation.comment}
                  </span>{" "}
                </Fragment>
              );
            })}
          </Paragraph> */}
        {/* </span> */}
        {buttonLink ? (
          <LinkButton
            to={buttonLink}
            variant="tertiary/medium"
            className="mb-1 place-self-end"
            TrailingIcon={() => (
              <AnimatingArrow theme="bright" direction="topRight" />
            )}
          >
            {buttonText}
          </LinkButton>
        ) : null}
      </GridContent>
    </GridRow>
  );
}
