import { table } from "console";
import { m } from "framer-motion";
import "keen-slider/keen-slider.min.css?__remix_sideEffect__";
import { useKeenSlider } from "keen-slider/react";
import React from "react";
import { cn } from "~/utils/cn";

type sliderProps = {
  className?: string;
  children: React.ReactNode;
  loop: boolean;
  renderMode: "precision" | "custom" | "performance";
  drag: boolean;
  initial: number;
  perViewMobile?: number | "auto";
  perViewTablet?: number | "auto";
  perViewDesktop?: number | "auto";
  spacingMobile?: number;
  spacingTablet?: number;
  spacingDesktop?: number;
  originMobile?: "center" | "auto";
  originTablet?: "center" | "auto";
  originDesktop?: "center" | "auto";
  modeMobile?: "free-snap" | "free" | "snap";
  modeTablet?: "free-snap" | "free" | "snap";
  modeDesktop?: "free-snap" | "free" | "snap";
  disabledMobile?: boolean;
  disabledTablet?: boolean;
  disabledDesktop?: boolean;
  spacing?: number;
  duration?: number;
  durationMobile?: number;
  durationTablet?: number;
  durationDesktop?: number;
  direction?: "left" | "right";
};

export default function HorizontalSlider({
  className,
  children,
  loop = true,
  renderMode = "precision",
  drag = true,
  initial,
  perViewMobile = "auto",
  perViewTablet = "auto",
  perViewDesktop = "auto",
  spacingMobile = 6,
  spacingTablet = 12,
  spacingDesktop = 12,
  originMobile = "center",
  originTablet = "center",
  originDesktop = "center",
  modeMobile = "free",
  modeTablet = "free",
  modeDesktop = "free",
  disabledMobile = false,
  disabledTablet = false,
  disabledDesktop = false,
  // in ms, so 100 seconds
  durationMobile = 100000,
  durationTablet = 100000,
  durationDesktop = 100000,
  direction = "right"
}: sliderProps) {
  const mobileAnimation = {
    duration: durationMobile,
    easing: (t: number) => t
  };
  const tabletAnimation = {
    duration: durationTablet,
    easing: (t: number) => t
  };
  const desktopAnimation = {
    duration: durationDesktop,
    easing: (t: number) => t
  };

  const [sliderRef] = useKeenSlider({
    loop: loop,
    renderMode: renderMode,
    drag: drag,
    initial: initial,
    // Mobile
    slides: {
      perView: perViewMobile,
      spacing: spacingMobile,
      origin: originMobile
    },
    mode: modeMobile,
    disabled: disabledMobile,

    created(s) {
      if (modeMobile === "snap" || modeMobile === "free-snap") {
        let timeout: NodeJS.Timeout | null = null;

        function clearSlideTimeout() {
          if (timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
        }

        function nextSlide() {
          clearSlideTimeout();
          timeout = setTimeout(() => {
            s.next();
          }, 2500);
        }

        nextSlide();

        s.on("updated", nextSlide);
        s.on("animationEnded", nextSlide);

        s.on("dragEnded", () => {
          clearSlideTimeout();
          setTimeout(nextSlide, 2500);
        });
      } else {
        if (direction === "left") {
          s.moveToIdx(-5, true, mobileAnimation);
        }
        if (direction === "right") {
          s.moveToIdx(5, true, mobileAnimation);
        }
      }
    },
    updated(s) {
      if (modeMobile === "free") {
        if (direction === "left") {
          s.moveToIdx(s.track.details.abs - 5, true, mobileAnimation);
        }
        if (direction === "right") {
          s.moveToIdx(s.track.details.abs + 5, true, mobileAnimation);
        }
      }
    },
    animationEnded(s) {
      if (modeMobile === "free") {
        if (direction === "left") {
          s.moveToIdx(s.track.details.abs - 5, true, mobileAnimation);
        }
        if (direction === "right") {
          s.moveToIdx(s.track.details.abs + 5, true, mobileAnimation);
        }
      }
    },

    breakpoints: {
      "(min-width: 640px)": {
        mode: modeTablet,
        disabled: disabledTablet,
        slides: {
          perView: perViewTablet,
          spacing: spacingTablet,
          origin: originTablet
        },
        created(s) {
          if (direction === "left") {
            s.moveToIdx(-5, true, tabletAnimation);
          }
          if (direction === "right") {
            s.moveToIdx(5, true, tabletAnimation);
          }
        },
        updated(s) {
          if (modeMobile === "free") {
            if (direction === "left") {
              s.moveToIdx(s.track.details.abs - 5, true, tabletAnimation);
            }
            if (direction === "right") {
              s.moveToIdx(s.track.details.abs + 5, true, tabletAnimation);
            }
          } else {
            return;
          }
        },
        animationEnded(s) {
          if (modeMobile === "free") {
            if (direction === "left") {
              s.moveToIdx(s.track.details.abs - 5, true, tabletAnimation);
            }
            if (direction === "right") {
              s.moveToIdx(s.track.details.abs + 5, true, tabletAnimation);
            }
          } else {
            return;
          }
        }
      },
      "(min-width: 1024px)": {
        mode: modeDesktop,
        disabled: disabledDesktop,
        slides: {
          perView: perViewDesktop,
          spacing: spacingDesktop,
          origin: originDesktop
        },
        created(s) {
          if (direction === "left") {
            s.moveToIdx(-5, true, desktopAnimation);
          }
          if (direction === "right") {
            s.moveToIdx(5, true, desktopAnimation);
          }
        },
        updated(s) {
          if (direction === "left") {
            s.moveToIdx(s.track.details.abs - 5, true, desktopAnimation);
          }
          if (direction === "right") {
            s.moveToIdx(s.track.details.abs + 5, true, desktopAnimation);
          }
        },
        animationEnded(s) {
          if (direction === "left") {
            s.moveToIdx(s.track.details.abs - 5, true, desktopAnimation);
          }
          if (direction === "right") {
            s.moveToIdx(s.track.details.abs + 5, true, desktopAnimation);
          }
        }
      }
    }
  });

  const sliderChildren = React.Children.map(children, (child, index) =>
  <div className="keen-slider__slide" key={index}>
      {child}
    </div>
  );

  return (
    <div
      ref={sliderRef}
      className={cn(
        "keen-slider relative flex w-full cursor-grab overflow-hidden active:cursor-grabbing",
        className
      )}>

      {sliderChildren}
    </div>);

}