import { transformCode } from "~/utils/transformCode";

type Example = {
  id?: string;
  tab: string;
  title: string;
  icon: any;
  code: string;
  filename?: string;
  buttonText?: string;
  buttonLink?: string;
};

const examples: Example[] = [
  {
    tab: "AI tasks",
    title:
      "Reliably call AI APIs with no timeouts, automatic retrying, and tracing. Use existing Node.js SDKs and code from your repo.",
    icon: "/animations/sparkles.riv",
    code: `
// Generate an image using OpenAI Dall-E 3
export const generateContent = task({
  id: "generate-content",
  retry: {
    maxAttempts: 3,
  },
  run: async ({ theme, description }: Payload) => {
    const textResult = await openai.chat.completions.create({
      model: "gpt-4o",
      messages: generateTextPrompt(theme, description),
    });

    if (!textResult.choices[0]) {
      throw new Error("No content, retrying…");
    }

    const imageResult = await openai.images.generate({
      model: "dall-e-3",
      prompt: generateImagePrompt(theme, description),
    });

    if (!imageResult.data[0]) {
      throw new Error("No image, retrying…");
    }

    return {
      text: textResult.choices[0],
      image: imageResult.data[0].url,
    };
  },
});
  `,
  },
  {
    tab: "Video processing",
    title:
      "Process videos quickly without needing to manage infrastructure. Use FFmpeg with AI APIs to analyze videos, generate transcriptions, and more.",
    icon: "/animations/play-button.riv",
    code: `
// Transcribe a video using Deepgram
export const transcribeVideo = task({
  id: "transcribe",
  retry: {
    maxAttempts: 3,
  },
  machine: { preset: "large-2x" },
  run: async (payload: { id: string; url: string }) => {
    const downloadedFile = await downloadFile(payload.url);
    const extractedAudio = await convertToWav(downloadedFile);

    const { result, error } = await deepgram.listen.prerecorded.transcribeFile(
      fs.createReadStream(extractedAudio),
      {
        model: "nova",
      }
    );

    return await db.transcriptions.create(payload.id, result?.results);
  },
});
  `,
  },
  {
    tab: "Cron",
    title:
      "Schedule tasks to run at specific times. You can even create schedules for your users, in their timezone.",
    icon: "/animations/calendar.riv",
    code: `
// Sync Airtable to the database
export const syncAirtable = schedules.task({
  id: "sync-airtable",
  run: async ({ timestamp, lastTimestamp }) => {
    await airtable<StoreItems>("Store items")
      .select({
        filterByFormula: encodeURIComponent(
          \`AND(LAST_MODIFIED_TIME() >= \${lastTimestamp}, 
          LAST_MODIFIED_TIME() <= \${timestamp})\`
        ),
      })
      .eachPage(
        async (records, fetchNextPage) => {
          for (const record of records) {
            const result = await processItem(record.fields);
            await db.storeItems.upsert(result.id, result);
          }

          // Repeat for the next page
          fetchNextPage();
        }
      );
  },
});
  `,
    buttonText: "Learn more about cron schedules",
    buttonLink: "/product/scheduled-tasks",
  },
  {
    tab: "Waits",
    title:
      "Wait for a period of time, until a date, or for events before continuing. We’ll pause execution while waiting.",
    icon: "/animations/hour-glass.riv",
    code: `
// Cancel abandoned shopping carts after 5 mins
export const checkoutCart = task({
  id: "checkout-cart",
  run: async (cart: Cart) => {
    const checkout = await startCheckout(cart.id);
    await wait.for({ minutes: 5 });

    const updatedCart = await db.carts.get(cart.id);
    if (updatedCart.status === "cancelled") {
      return {
        status: "cancelled",
      };
    }
    if (updatedCart.modifiedAt > checkout.modifiedAt) {
        await checkoutCart.trigger(updatedCart);
        return {
            status: "modified",
        };
    }

    await processOrder.trigger({ cart: updatedCart, checkout });
    return {
      status: "completed",
    };
  },
});
      `,
  },
  {
    tab: "Workflows",
    title:
      "Create data-driven workflows with branching, waits, subtasks, and retries. Trigger other tasks in sequence or parallel and wait for them to complete.",
    icon: "/animations/flow-chart.riv",
    code: `
// Dynamic workflow using the payload data
export const workflow = task({
  id: "workflow",
  run: async ({ id, steps }: Payload) => {
    for (const step of steps) {
      switch (step.type) {
        case "wait":
          await wait.for({ seconds: step.seconds });
          break;
        case "email":
          await sendEmails.batchTriggerAndWait(step.emails);
          break;
        case "conditional":
          const result = await conditional.triggerAndWait(step);

          if (!result.ok || !result.output.shouldContinue) {
            return {
              id,
              status: "canceled",
              reason: "condition-not-met",
              condition: step.condition,
            };
          }
          break;
      }
    }

    return {
      id,
      status: "completed",
    };
  },
});`,
  },
  {
    tab: "Concurrency",
    title:
      "Easily control how many tasks you run at once. Create on-demand queues for each of your users, with higher concurrency for paying customers.",
    icon: "/animations/concurrency.riv",
    code: `
// Different concurrency for different user groups
export const generateAnswers = task({
  id: "generate-answers",
  queue: {
    // One-at-a-time
    concurrencyLimit: 1,
  },
  run: async ({ userId, question }: Payload) => {
    // Do clever and expensive things
  },
});

// Your backend
await generateAnswers.trigger(
  { userId: user.id, question },
  {
    queue: {
      name: user.plan,
      // Paid users can run 10 at a time
      concurrencyLimit: user.plan === "paid" ? 10 : 1,
    },
    // Each user has their own queue
    concurrencyKey: user.id,
  }
);`,
    buttonText: "Learn more about concurency",
    buttonLink: "/product/concurrency-and-queues",
  },
  {
    tab: "Retries",
    title:
      "Uncaught errors automatically cause retries of tasks using your settings. Plus there are helpers for granular retrying inside your tasks.",
    icon: "/animations/arrow-retry-5.riv",
    code: `
// Combine different retry strategies
export const myTask = task({
  id: "my-task",
  retry: {
    maxAttempts: 10,
    minTimeoutInMs: 1_000,
    maxTimeoutInMs: 30_000,
    factor: 2,
  },
  run: async ({ userId, question }: Payload) => {
    // If this throws (and isn't caught), the task will be retried
    const result = await thisCanThrowAnError();

    try {
      const result = await thisCanThrowAnError();
    } catch (error) {
      // We caught so the task won't be retried
      logger.error("This is an error", { error });
    }

    // You can also do local retrying using our helpers
    const result2 = await retry.onThrow(
      ({ attempt }) => {
        logger.log(\`Attempt #\${attempt}\`);
        return thisCanThrowAnError();
      },
      { maxAttempts: 5 }
    );
  },
});`,
  },
  {
    tab: "PDF to images",
    title:
      "Read and write to your database from your tasks. Use packages that require WASM, like MuPDF that converts PDFs to images.",
    icon: "/animations/document-scan.riv",
    code: `
// Convert a pdf to images
export const convertPDFToImages = task({
  id: "convert-pdf-to-images",
  run: async ({ documentId }: Payload) => {
    const document = await prisma.document.findUnique({
      where: { id: documentId },
    });

    if (!document) {
      return { success: false };
    }

    const signedUrl = await getFile({ id: document.fileId });

    const results = await imageFromPage.batchTriggerAndWait(
      new Array(document.pageCount).fill(null).map((pageNumber) => ({
        payload: {
          documentId,
          pageNumber,
          url: signedUrl,
        },
      }))
    );

    await storeResults(results);

    return {
      success: true,
      message: "Successfully converted PDF to images",
    };
  },
});`,
  },
  {
    tab: "Semantic search",
    title:
      "Use more advanced AI techniques like semantic search and RAG with simple reliability.",
    icon: "/animations/magnifying-glass.riv",
    code: `
// Deliver great results using semantic search
export const getAnswers = task({
  id: "get-answers",
  retry: {
    maxAttempts: 3,
  },
  run: async (payload: { question: string }) => {
    const embeddings = await openai.embeddings.create({
      input: payload.question,
      model: "text-embedding-3-small",
    });

    const embedding = embeddings.data[0].embedding;
    if (!embedding) {
      throw new Error("Failed to create embedding");
    }

    const index = pinecone.index("semantic-search");
    const results = await index.query({
      topK: 3,
      vector: embedding,
    });

    const messages = generatePrompt(payload.question, results.matches);
    const result = await openai.chat.completions.create({
      model: "gpt-4-turbo",
      messages,
    });

    return result.choices;
  },
});
`,
  },
  {
    tab: "AI Agent",
    title:
      "Build reliable AI agents with simple retrying and no limits on how long they can run for.",
    icon: "/animations/ai-agent.riv",
    code: `
// Use an AI agent to complete a series of tasks
export const agent = task({
  id: "agent",
  run: async ({ objective, url }: Payload) => {
    let input = { objective, url };

    for (let i = 0; i < MAX_ACTIONS; i++) {
      const actionResult = await agentAction.triggerAndWait({ objective, url });

      if (!actionResult.ok) {
        // This happens if the agentAction has thrown an error until it ran out of retries
        throw new Error(\`Action failed: $\{actionResult.error}\`);
      }

      // If the agentAction is successful we can return the result
      if (actionResult.output.success) {
        return actionResult.output.result;
      }

      // Otherwise we can continue with the next action
      input = actionResult.output.next;
    }

    // If we reach this point, we have run out of retries
    throw new Error("Agent has run out of actions to perform.");
  },
});
`,
  },
];

export const heroCodeExamples = examples.map((example) => ({
  ...example,
  annotatedCode: transformCode(example.code),
}));
