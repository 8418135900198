import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { GridContent, GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import { Header2 } from "~/components/primitives/text/Header";
import { AppsmithLogo } from "./logos/AppsmithLogo";
import { CalComLogo } from "./logos/CalComLogo";
import { DeelLogo } from "./logos/DeelLogo";
import { LyftLogo } from "./logos/LyftLogo";
import { MiddayLogo } from "./logos/MiddayLogo";
import { ShopifyLogo } from "./logos/ShopifyLogo";
import { TldrawLogo } from "./logos/TldrawLogo";
import { TwilioLogo } from "./logos/TwilioLogo";
import { VerizonLogo } from "./logos/VerizonLogo";

type TrustedBy = {
  id: string;
  logo: React.ReactNode;
};

const companies: TrustedBy[] = [
  {
    id: "verizon",
    logo: <VerizonLogo />,
  },
  {
    id: "shopify",
    logo: <ShopifyLogo className="px-0.5" />,
  },
  {
    id: "deel",
    logo: <DeelLogo className="h-8 px-1.5 pb-1 pt-0.5" />,
  },
  {
    id: "lyft",
    logo: <LyftLogo className="py-1" />,
  },
  {
    id: "tldraw",
    logo: <TldrawLogo className="h-8 p-1.5" />,
  },
  {
    id: "appsmith",
    logo: <AppsmithLogo />,
  },
  {
    id: "caldotcom",
    logo: <CalComLogo className="h-12" />,
  },
  {
    id: "twilio",
    logo: <TwilioLogo className="h-8 p-1.5" />,
  },
  {
    id: "midday",
    logo: <MiddayLogo className="h-8 p-1" />,
  },
];

const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

type LogoSlot = {
  logoId?: string;
};

export function TrustedBySection() {
  const [logoSlot, setLogoSlot] = useState<LogoSlot[]>([
    { logoId: "verizon" },
    { logoId: "shopify" },
    { logoId: "twilio" },
    { logoId: "caldotcom" },
    { logoId: "tldraw" },
    { logoId: "appsmith" },
  ]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      let removedLogoId: string | undefined;

      setLogoSlot((slots) => {
        const newLogos = [...slots];
        const randomIndexToReplace = Math.floor(
          Math.random() * newLogos.length
        );
        removedLogoId = newLogos[randomIndexToReplace].logoId;
        newLogos[randomIndexToReplace] = { logoId: undefined };
        return newLogos;
      });

      await new Promise((resolve) => setTimeout(resolve, 750));

      setLogoSlot((slots) => {
        const newLogos = [...slots];
        const nonDisplayedLogos = companies.filter(
          (company) =>
            !slots.some((slot) => slot.logoId === company.id) &&
            company.id !== removedLogoId
        );
        const randomLogoToAdd =
          nonDisplayedLogos[
            Math.floor(Math.random() * nonDisplayedLogos.length)
          ];
        const emptySlotIndex = newLogos.findIndex((slot) => !slot.logoId);
        newLogos[emptySlotIndex] = { logoId: randomLogoToAdd.id };
        return newLogos;
      });
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Section id="trusted-by">
      <GridRow>
        <GridContent removePaddingY justifyCenter className="col-span-12 py-4">
          <Header2 variant={"extra-small"} textCenter className="text-dimmed">
            Trusted by developers at companies all over the world
          </Header2>
        </GridContent>
      </GridRow>
      <GridRow removeBordersX autoGridLines={false}>
        <GridContent
          removePaddingX
          removePaddingY
          className="col-span-1 border-r border-grid-dimmed bg-visible-grid-2 sm:col-span-12 sm:bg-visible-grid-3 md:bg-visible-grid-6"
        >
          <div className="grid h-full grid-cols-2 place-items-center sm:grid-cols-3 md:grid-cols-6">
            {logoSlot.map((slot, index) => {
              const company = companies.find(
                (company) => company.id === slot.logoId
              );
              return (
                <div
                  key={index}
                  className="relative flex h-20 w-full items-center justify-center overflow-hidden border-b border-grid-dimmed"
                >
                  <AnimatePresence>
                    {company ? (
                      <motion.div
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={variants}
                        key={index}
                        transition={{ duration: 0.75 }}
                      >
                        <div
                          key={company.id}
                          className="flex h-10 items-center justify-center text-charcoal-300"
                        >
                          {company.logo}
                        </div>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </div>
              );
            })}
          </div>
        </GridContent>
      </GridRow>
    </Section>
  );
}
