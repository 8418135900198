import { Alignment, Fit, Layout } from "@rive-app/canvas";
import { useRive } from "@rive-app/react-canvas";
import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { GridContent, GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import { Header3 } from "~/components/primitives/text/Header";
import { Paragraph } from "~/components/primitives/text/Paragraph";
import { SectionHeader } from "../../../components/primitives/text/SectionHeader";

export function AlertsForErrors() {
  const { rive, RiveComponent } = useRive({
    src: "/animations/alerts-for-errors.riv",
    animations: ["default", "hover"],
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.BottomCenter,
    }),
    autoplay: false,
  });

  const ref = useRef(null);
  const isInView = useInView(ref);
  const isMobile = useMediaQuery({ query: "(max-width: 568px)" });

  useEffect(() => {
    if (isMobile && isInView) {
      rive && rive.play("hover");
    } else {
      rive && rive.play("default");
    }
  }, [isMobile, isInView, rive]);

  return (
    <div
      ref={ref}
      className="relative flex flex-col place-content-center items-center justify-center overflow-hidden"
      onMouseEnter={() => {
        rive && rive.play("hover");
      }}
      onMouseLeave={() => rive && rive.play("default")}
    >
      <div className="flex h-full max-w-lg flex-1 flex-col justify-center p-6 sm:p-8 lg:max-w-full lg:justify-start">
        <Header3 variant="base" spacing>
          Alerts for errors
        </Header3>
        <Paragraph>
          Get notified via email, Slack or webhooks when your tasks or
          deployments fail.
        </Paragraph>
      </div>
      <div className="z-10 flex w-full items-center justify-center overflow-hidden">
        <RiveComponent className="h-[150px] w-[300px] sm:h-[260px] sm:w-[520px] lg:h-[200px] lg:w-[400px]" />
      </div>
      <img
        src="/patterns/alerts-bg.png"
        alt="hero"
        className="absolute bottom-0 left-1/2 z-0 w-full max-w-3xl -translate-x-1/2 
transform object-cover opacity-50"
      />
    </div>
  );
}

export function AdvancedFiltering() {
  const { rive, RiveComponent } = useRive({
    src: "/animations/advanced-filtering.riv",
    animations: ["default", "hover"],
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.BottomCenter,
    }),
    autoplay: false,
  });

  const ref = useRef(null);
  const isInView = useInView(ref);
  const isMobile = useMediaQuery({ query: "(max-width: 568px)" });

  useEffect(() => {
    if (isMobile && isInView) {
      rive && rive.play("hover");
    } else {
      rive && rive.play("default");
    }
  }, [isMobile, isInView, rive]);

  return (
    <div
      ref={ref}
      className="relative flex flex-col place-content-center items-center justify-center overflow-hidden"
      onMouseEnter={() => {
        rive && rive.play("hover");
      }}
      onMouseLeave={() => rive && rive.play("default")}
    >
      <div className="flex h-full max-w-lg flex-1 flex-col justify-center p-6 sm:p-8 lg:max-w-full lg:justify-start">
        <Header3 spacing variant="base">
          Advanced filtering
        </Header3>
        <Paragraph>
          Find runs fast using advanced filtering options, then apply bulk
          actions to multiple tasks at once.
        </Paragraph>
      </div>
      <div className="z-10 flex w-full items-center justify-center overflow-hidden">
        <RiveComponent className="h-[150px] w-[300px] sm:h-[260px] sm:w-[520px] lg:h-[200px] lg:w-[400px]" />
      </div>
      <div className="absolute bottom-0 z-20 h-20 w-full bg-gradient-to-t from-background to-background/0" />
      <img
        src="/patterns/alerts-bg.png"
        alt="hero"
        className="absolute bottom-0 left-1/2 z-0 w-full max-w-3xl -translate-x-1/2 
transform object-cover opacity-50"
      />
    </div>
  );
}

export function Versioning() {
  const { rive, RiveComponent } = useRive({
    src: "/animations/versioning.riv",
    animations: ["default", "hover"],
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.BottomCenter,
    }),
    autoplay: false,
  });
  const ref = useRef(null);
  const isInView = useInView(ref);
  const isMobile = useMediaQuery({ query: "(max-width: 568px)" });

  useEffect(() => {
    if (isMobile && isInView) {
      rive && rive.play("hover");
    } else {
      rive && rive.play("default");
    }
  }, [isMobile, isInView, rive]);
  return (
    <div
      ref={ref}
      className="relative flex flex-col place-content-center items-center justify-center overflow-hidden"
      onMouseEnter={() => {
        rive && rive.play("hover");
      }}
      onMouseLeave={() => rive && rive.play("default")}
    >
      <div className="flex max-w-lg flex-1 flex-col justify-center p-6 sm:p-8 lg:max-w-full lg:justify-start">
        <Header3 spacing variant="base">
          Versioning
        </Header3>
        <Paragraph>
          Each deploy is an atomic version ensuring started tasks are not
          affected by code changes.
        </Paragraph>
      </div>
      <div className="z-10 flex w-full items-center justify-center overflow-hidden ">
        <RiveComponent className="h-[150px] w-[300px] sm:h-[260px] sm:w-[520px] lg:h-[200px] lg:w-[400px]" />
      </div>
      <div className="absolute bottom-0 z-20 h-20 w-full bg-gradient-to-t from-background via-background/75 to-background/0" />
      <img
        src="/patterns/alerts-bg.png"
        alt="hero"
        className="absolute bottom-0 left-1/2 z-0 w-full max-w-3xl -translate-x-1/2 
transform object-cover opacity-50"
      />
    </div>
  );
}

export function FindAndFixSection() {
  return (
    <Section id="find-and-fix">
      <SectionHeader title="Find and fix bugs fast" />
      <GridRow
        autoGridLines={false}
        className="divide-y divide-grid-dimmed lg:divide-x lg:divide-y-0"
      >
        <GridContent
          removePaddingX
          removePaddingY
          className="col-span-1 sm:col-span-12 lg:col-span-4"
        >
          <AlertsForErrors />
        </GridContent>
        <GridContent
          removePaddingX
          removePaddingY
          className="col-span-1 sm:col-span-12 lg:col-span-4"
        >
          <AdvancedFiltering />
        </GridContent>
        <GridContent
          removePaddingX
          removePaddingY
          className="col-span-1 sm:col-span-12 lg:col-span-4"
        >
          <Versioning />
        </GridContent>
      </GridRow>
    </Section>
  );
}
