import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { cn } from "~/utils/cn";
import { Button } from "./primitives/Buttons";

type ExpandableContainerProps = {
  children: React.ReactNode;
  // this is in pixels
  heightCollapsedPx?: number;
  heightExpanded?: string;
  textCollapsed?: string;
  textExpanded?: string;
  hideOnMobile?: boolean;
  className?: string;
  transitionDuration?: number;
};

export function ExpandableContainer({
  children,
  heightCollapsedPx = 500,
  textCollapsed = "Show more",
  textExpanded = "Show less",
  hideOnMobile = false,
  transitionDuration = 0.5,
  className,
}: ExpandableContainerProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const toggleExpansion = () => setIsExpanded((prevOpen) => !prevOpen);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 400);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <motion.div
      initial={false}
      animate={{
        height: isMobile || isExpanded ? "auto" : heightCollapsedPx,
      }}
      transition={{ duration: transitionDuration }}
      className={cn("relative overflow-hidden", className)}
    >
      <div className={cn(isMobile ? "pb-6" : "pb-24")}>{children}</div>
      <div
        className={cn(
          "absolute bottom-0 z-0 h-48 w-full flex-col items-center justify-end pb-12 sm:flex",
          hideOnMobile && "hidden"
        )}
      >
        <div className="z-10">
          <Button
            onClick={toggleExpansion}
            variant="tertiary/medium"
            className="bg-background group-hover:transition"
          >
            {isExpanded ? textExpanded : textCollapsed}
          </Button>
        </div>
        <motion.div
          initial={false}
          animate={{
            opacity: isExpanded ? 0 : 1,
          }}
          transition={{ duration: transitionDuration }}
          className="pointer-events-none absolute bottom-0 z-0 ml-0.5 h-48 w-full bg-gradient-to-b from-background/0 via-background/80 to-background pl-20 sm:flex"
        />
      </div>
    </motion.div>
  );
}
