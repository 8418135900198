import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import { motion } from "framer-motion";
import { CarouselNavArrow } from "~/components/CarouselNavArrow";
import { CheckedBulletItem } from "~/components/CheckedBulletItem";
import { AnimatedCodeHighlighting } from "~/components/code/AnimatedCodeHighlighting";
import { AnimatingArrow } from "~/components/primitives/AnimatingArrow";
import { LinkButton } from "~/components/primitives/Buttons";
import { GridContent, GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import {
  Tabs,
  TabsContent,
  TabsHeroTrigger,
  TabsList,
} from "~/components/primitives/Tabs";
import { useCarouselScroll } from "~/hooks/useCarouselScroll";
import { heroCodeExamples } from "./heroCodeExamples";

const tabAnimation = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0 },
};

export function HeroSection() {
  const {
    carouselRef,
    scrollLeft,
    scrollRight,
    isFullyScrolledLeft,
    isFullyScrolledRight,
    handleScroll,
  } = useCarouselScroll();

  return (
    <Section id="hero">
      {/* <GridRow
        removeTopBorder
        className="border-l-transparent sm:bg-visible-grid-4 md:pt-10 lg:bg-visible-grid-12 lg:pt-14"
      >
        <BadgeDefault
          leadingIcon={<SignalIcon />}
          badgeText="Trigger.dev v3 early access"
          href="/blog/v3-announcement/"
          className="col-span-1 sm:col-span-12 md:col-span-6 lg:col-span-3"
        />
      </GridRow> */}
      <GridRow
        removeBorderT
        autoGridLines={false}
        removeBordersX
        className="border-l border-r pt-4 sm:border-l-transparent sm:bg-visible-grid-2 sm:py-32 md:bg-visible-grid-4 lg:bg-visible-grid-12"
      >
        <GridContent
          className="sm:col-span-12 md:col-span-6 lg:col-span-7"
          removePaddingX
          removePaddingY
        >
          <h1
            id="top"
            className="px-3 py-4 font-title text-[11.1vmin] font-[650] leading-[11.1vmin] text-bright sm:py-0 md:text-[5.8vmin] md:leading-[5.8vmin] lg:text-7xl"
          >
            Open source background jobs with no timeouts
          </h1>
        </GridContent>
        <GridContent
          flipContentOrder
          className="mt-4 sm:col-span-12 sm:mt-8 md:col-span-6 md:mt-3 lg:col-span-5 lg:mt-5"
          removePaddingY
        >
          <div className="flex flex-col gap-6">
            <ul className="flex flex-col gap-2">
              <CheckedBulletItem
                ariaLabel="Use the Trigger.dev typescript sdk to easily create workflows in code"
                text={"JavaScript and TypeScript SDK"}
              />
              <CheckedBulletItem text={"Write reliable code by default"} />
              <CheckedBulletItem text={"No infrastructure to manage"} />
              <CheckedBulletItem text={"Works with your existing tech stack"} />
            </ul>

            <LinkButton
              variant="primary/large"
              TrailingIcon={() => (
                <AnimatingArrow variant="large" theme="dark" />
              )}
              data-action="herobutton__clicked"
              to="https://cloud.trigger.dev"
              className="group mb-16 mt-4 sm:mb-0 sm:mt-0"
              target="_self"
            >
              Get started now
            </LinkButton>
          </div>
        </GridContent>
      </GridRow>
      <Tabs
        defaultValue={heroCodeExamples[0].tab}
        className="flex w-full flex-col items-center"
      >
        <GridRow
          widthOverflow
          removeBordersX
          removeBorderT
          autoGridLines={false}
          className="w-full"
        >
          <GridContent
            className="col-span-12"
            removePaddingY
            removePaddingX
            flipContentOrder
          >
            <div
              ref={carouselRef}
              onScroll={handleScroll}
              className="flex w-full items-center overflow-x-auto py-2 scrollbar-none"
            >
              <CarouselNavArrow
                arrowDirection="left"
                onClick={scrollLeft}
                isFullyScrolled={isFullyScrolledLeft}
              />
              <div className="container mx-auto max-w-[1222px]">
                <TabsList className="gap-2 pl-3 pr-2 [@media(min-width:1224px)]:pl-0">
                  {heroCodeExamples.map((example, index) => (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={tabAnimation}
                      transition={{
                        duration: 0.5,
                        delay: 0.1 * index,
                        type: "spring",
                        stiffness: 70,
                      }}
                      key={example.tab}
                    >
                      <AnimatedTab
                        title={example.tab}
                        key={example.tab}
                        animation={example.icon}
                      />
                    </motion.div>
                  ))}
                </TabsList>
              </div>
              <CarouselNavArrow
                arrowDirection="right"
                onClick={scrollRight}
                isFullyScrolled={isFullyScrolledRight}
              />
            </div>
          </GridContent>
        </GridRow>

        {heroCodeExamples.map((example) => (
          <TabsContent value={example.tab} key={example.tab} className="mt-0">
            <AnimatedCodeHighlighting
              title={example.title}
              annotatedCode={example.annotatedCode}
              isActive={true}
              initialDelay={2_000}
              period={2_000}
              buttonLink={example.buttonLink}
              buttonText={example.buttonText}
            />
          </TabsContent>
        ))}
      </Tabs>
    </Section>
  );
}

function AnimatedTab({
  title,
  animation,
}: {
  title: string;
  animation: string;
}) {
  const { rive, RiveComponent } = useRive({
    src: animation,
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.Center,
    }),
    autoplay: false,
  });

  return (
    <TabsHeroTrigger
      value={title}
      key={title}
      aria-label={title}
      onMouseEnter={() => {
        rive && rive.play("hover");
      }}
      onMouseLeave={() => rive && rive.play("default")}
    >
      <RiveComponent className="size-6" />
      {title}
    </TabsHeroTrigger>
  );
}
