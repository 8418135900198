import type { MouseEventHandler } from "react";
import React from "react";
import { cn } from "~/utils/cn";
import { AnimatingArrow } from "./primitives/AnimatingArrow";

type CarouselNavArrowProps = {
  arrowDirection: "left" | "right";
  onClick: MouseEventHandler<HTMLDivElement>;
  isFullyScrolled: boolean;
  className?: string;
};

export const CarouselNavArrow: React.FC<CarouselNavArrowProps> = ({
  arrowDirection = "left",
  onClick,
  isFullyScrolled,
  className,
}) => {
  return (
    <>
      {arrowDirection === "left" ? (
        <div
          onClick={onClick}
          className={cn(
            "group absolute -left-[26px] top-0 hidden h-full cursor-pointer items-center rounded-l border-y border-l border-transparent px-1 transition duration-300 hover:border-grid-dimmed hover:bg-charcoal-850 [@media(min-width:1490px)]:flex",
            isFullyScrolled ? "cursor-default opacity-0" : "opacity-100",
            className
          )}
        >
          <AnimatingArrow direction="left" className="-left-1" />
        </div>
      ) : (
        <div
          onClick={onClick}
          className={cn(
            "group absolute -right-[26px] top-0 hidden h-full cursor-pointer items-center rounded-r border-y border-r border-transparent px-1 transition duration-300 hover:border-grid-dimmed hover:bg-charcoal-850 [@media(min-width:1490px)]:flex",
            isFullyScrolled ? "cursor-default opacity-0" : "opacity-100",
            className
          )}
        >
          <AnimatingArrow direction="right" className="-left-1" />
        </div>
      )}
    </>
  );
};
