import { useRef, useState } from "react";
import { cn } from "~/utils/cn";

type PatternHoverBackground = {
  className?: string;
  href?: string;
  children: React.ReactNode;
  borderColour?: string;
  hoverColour?: string;
  padding?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
  spotlightStartPositionX?: number;
  spotlightStartPositionY?: number;
  onMouseEnter?: () => void;
  startOpacity?: number;
  exitOpacity?: number;
  backgroundPattern?: string;
  mouseTracking?: boolean;
};

export const PatternHoverBackground: React.FC<PatternHoverBackground> = ({
  children,
  className,
  padding = "false",
  ref,
  spotlightStartPositionX = 0,
  spotlightStartPositionY = 0,
  startOpacity = 1,
  exitOpacity = 0,
  backgroundPattern = "bg-pattern-cross-charcoal",
  mouseTracking = true,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [position, setPosition] = useState({
    x: spotlightStartPositionX,
    y: spotlightStartPositionY,
  });
  const [opacity, setOpacity] = useState(startOpacity);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!divRef.current || isFocused) return;

    const div = divRef.current;
    const rect = div.getBoundingClientRect();

    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    // Constrain x and y to the boundaries of the div
    if (x < 0) x = 0;
    if (y < 0) y = 0;
    if (x > rect.width) x = rect.width;
    if (y > rect.height) y = rect.height;

    setPosition({ x, y });
  };

  const handleFocus = () => {
    setIsFocused(true);
    setOpacity(1);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setOpacity(0);
  };

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(exitOpacity);
  };
  return (
    <div ref={ref} className={cn("group relative flex h-full w-full")}>
      <div
        className={cn(
          "pointer-events-none absolute h-full w-full opacity-75 transition duration-500 group-hover:opacity-75 md:opacity-20",
          backgroundPattern
        )}
      />

      <div
        className={cn(
          "group pointer-events-none absolute -inset-px transition duration-300",
          padding ? "p-4" : "p-0"
        )}
        style={{
          opacity,
          background: `radial-gradient(200px circle at ${position.x}px ${position.y}px,  rgb(18, 19, 23, 
            0), rgb(18, 19, 23, 
            1)
          )`,
        }}
      />

      <div
        className={cn("h-full w-full", className)}
        ref={divRef}
        onMouseMove={mouseTracking ? handleMouseMove : undefined}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={mouseTracking ? handleMouseEnter : undefined}
        onMouseLeave={mouseTracking ? handleMouseLeave : undefined}
      >
        {children}
      </div>
    </div>
  );
};
